import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

import { FormPageSection } from "../../../layout/FormPageSection";
import createCRSection from "./createCRSectionFields";

const isRequiredFieldEmpty = invoice => {
  return (
    !_get(invoice, "remitAddressId") ||
    !_get(invoice, "number") ||
    !_get(invoice, "invoicedDate") ||
    !_get(invoice, "dueDate")
  );
};

// eslint-disable-next-line complexity
export const validateOkToIssue = ({
  dirty,
  values: invoice,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  if (dirty) {
    return "Update required as changes detected on Invoice.";
  }

  if (isRequiredFieldEmpty(invoice)) {
    return "Invoice cannot be issue due to required fields missing.";
  }

  if (_get(invoice, "number") == "TBD") {
    return "Invalid invoice number.";
  }

  const negativeNotProcessedFields = Object.keys(invoice).filter(key => {
    if (
      !key.endsWith("NotProcessed") ||
      ["freightWarehousingNotProcessed"].includes(key)
    ) {
      return false;
    }

    const row = key.replace("NotProcessed", "");
    const rowAssetsToDate = invoice[`${row}AssetsToDate`];
    const rowDepositsToDate = invoice[`${row}DepositsToDate`];
    const rowMaximum = invoice[`${row}Maximum`];

    const rowNotProcessed =
      Math.round((rowMaximum - (rowAssetsToDate + rowDepositsToDate)) * 1000) /
      1000;

    return rowNotProcessed < 0;
  });

  const notProcessedErrors = negativeNotProcessedFields.map(field =>
    startCase(field)
  );

  return _isEmpty(notProcessedErrors)
    ? null
    : `Invoice cannot be marked as Ok to Issue. ${notProcessedErrors.join(
        notProcessedErrors.length === 2 ? " and " : ", "
      )}
        cannot be negative.`;
};

const Section = ({
  okToIssue,
  updateInvoice,
  allChangesLocked,
  isProjectClosed,
  ...formikProps
}) => {
  const okToIssueError = validateOkToIssue(formikProps);

  const isOkToIssueDisabled = okToIssue || isProjectClosed || okToIssueError;
  const fields = useMemo(
    () =>
      createCRSection(
        formikProps.values,
        !isOkToIssueDisabled,
        okToIssue,
        updateInvoice,
        allChangesLocked,
        okToIssueError
      ),
    [
      formikProps.values,
      isOkToIssueDisabled,
      okToIssue,
      updateInvoice,
      allChangesLocked,
      okToIssueError,
    ]
  );

  return (
    <FormPageSection
      headerText="Check Request Details"
      fields={fields}
      {...formikProps}
    />
  );
};

Section.propTypes = {
  canIssue: PropTypes.bool,
  okToIssue: PropTypes.bool,
};

export default Section;
